.overall-wrapper {
    display: flex;
    justify-content: center;
    overflow: auto;
    width: 100%;
    height: 100%;
    opacity: 1;
  }
  .previewImage{
  display: none;
  }
  
  .previewImage canvas{
    width: 90%;
    }
    .size{
      width: 90%;
    }
  
  @media (max-width: 900px) {
    .overall-wrapper {
      justify-content: flex-start;
      /* display: none; */
      opacity: 0;
      position: absolute;
      top: -20000px;
      left: -20000px;
    }
    .previewImage{
      display: flex;
      }
  }
  
  .scroll {
    background: #1a2734;
    color: white;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
  }
  
  .preview-section {
    display: flex;
    justify-content: space-around;
    align-items: baseline;
    width: 100%;
  }
  
  .preview-section p {
    font-family: "primaryBold";
    color: var(--all-courses-acourse-bGandBtn);
    font-size: 14px;
    line-height: 16px;
  }
  
  .preview-section button {
    height: 36px;
    padding: 0px 8px 0px;
    border: 1px solid #971eff;
    border-radius: 8px;
    font-family: "primaryMedium";
    font-size: 12px;
    line-height: 12px;
    margin-right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 67px;
    margin-bottom: 20px;
  }
  
  .bold {
    font-weight: 700;
    font-size: 20px;
    text-transform: propercase;
    font-family: primaryBold;
  }
  
  .semi-bold {
    font-weight: 500;
    font-size: 13px;
    line-height: 14px;
  }
  
  .resume {
    /* width: 210mm;
    height: 297mm; */
    width: 210mm;
    height: 297mm;
    display: flex;
    margin: 50px auto;
    font-family: "primaryMedium";
    margin: 0;
    font-size: 14px;
    padding: 0;
    box-sizing: border-box;
    list-style: none;
    word-wrap: break-word;
    word-break: break-word;
  }
  
  .resume .resume_left {
    width: 310px;
    min-height: 9.1in;
    background: #252044;
  }
  
  .resume .resume_left .resume_profile {
    width: 120px;
    height: 120px;
    margin-left: 99px;
    margin-top: 50px;
  }
  
  .resume .resume_left .resume_profile img {
    width: 100%;
    height: 100%;
  }
  
  .resume .resume_left .resume_content {
    padding: 0 25px;
  }
  
  .resume .title {
    margin-bottom: 10px;
  }
  .title h1 {
    font-family: primaryBold;
    font-size: 20px;
    line-height: 23px;
  
    color: #fcfcfc;
  }
  
  .title h2 {
    font-family: primaryMedium;
    font-size: 12px;
    line-height: 14px;
    color: #fcfcfc;
  }
  
  /* for bio */
  .resume_about h3 {
    font-family: primaryMedium;
    font-size: 14px;
    line-height: 20px;
    color: #fcfcfc;
  }
  
  .title p {
    font-family: primaryBold;
    font-size: 18px;
    line-height: 16px;
    color: #fcfcfc;
  }
  
  .resume .resume_left .bold {
    color: #fff;
  }
  
  .resume .resume_left .regular {
    color: #fcfcfc;
  }
  
  .resume .resume_item {
    padding: 25px 0;
    padding-left: 18px;
  }
  
  .resume .resume_left .resume_item:last-child,
  .resume .resume_right .resume_item:last-child {
    border-bottom: 0px;
  }
  
  .resume .resume_left ul li {
    display: flex;
    margin-bottom: 10px;
    align-items: center;
  }
  .resume .resume_left ul {
    margin-top: 10px;
  }
  .resume .resume_left ul li:last-child {
    margin-bottom: 0;
  }
  
  .resume .resume_left ul li .icon {
    width: 35px;
    height: 35px;
    background: #fff;
    color: #0bb5f4;
    border-radius: 50%;
    margin-right: 15px;
    font-size: 16px;
    position: relative;
  }
  
  .resume .icon i,
  .resume .resume_right .resume_hobby ul li i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .resume .resume_left ul li .data {
    width: 100%;
    font-family: primaryMedium;
    font-size: 14px;
    line-height: 16px;
    color: #fcfcfc;
    word-wrap: break-word;
    word-break: break-word;
    display: flex;
    align-items: center;
  }
  .resume .resume_right ul li {
    width: 100%;
    font-family: primaryMedium;
    font-size: 14px;
    line-height: 16px;
    color: #fcfcfc;
    word-wrap: break-word;
    word-break: break-word;
    display: flex;
    align-items: center;
  }
  
  .resume .resume_left .resume_skills ul li {
    display: flex;
    margin-bottom: 10px;
    color: #fcfcfc;
    justify-content: space-between;
    align-items: center;
  }
  
  .resume .resume_left .resume_skills ul li .skill_name {
    /* width: 25%; */
    word-wrap: break-word;
    word-break: break-word;
  }
  
  .resume .resume_left .resume_skills ul li .skill_progress {
    width: 60%;
    margin: 0 5px;
    height: 5px;
    background: #009fd9;
    position: relative;
  }
  
  .resume .resume_left .resume_skills ul li .skill_per {
    width: 15%;
  }
  
  .resume .resume_left .resume_skills ul li .skill_progress span {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background: #fff;
  }
  
  .resume .resume_left .resume_social .semi-bold {
    color: #fff;
    margin-bottom: 3px;
  }
  
  .resume .resume_right {
    width: 520px;
    background: #1a2734;
    padding: 25px;
    position: relative;
  }
  
  .resume .resume_right .bold {
    color: #fcfcfc;
  }
  
  .resume .resume_right .resume_work ul,
  .resume .resume_right .resume_education ul {
    /* padding-left: 40px; */
    overflow: hidden;
  }
  
  .resume .resume_right ul li {
    position: relative;
  }
  
  .resume .resume_right ul li .date {
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 15px;
    color: #fcfcfc;
  }
  
  .resume .resume_right ul li .info {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
  }
  
  .resume .resume_right ul li:last-child .info {
    margin-bottom: 0;
  }
  
  .resume .resume_right .resume_work ul li:before,
  .resume .resume_right .resume_education ul li:before {
    content: "";
    position: absolute;
    top: 5px;
    left: -25px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    /* border: 2px solid #0bb5f4; */
  }
  
  /* @page {
    size: 8.3in 11in;
    margin: 0;
  } */
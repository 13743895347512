.jsoneditor {
    background-color: #2d2d2d;
    color: #ccc;
  }
  
  .jsoneditor-menu {
    background-color: #3c3c3c;
  }
  
  .jsoneditor-menu button {
    color: #ccc;
  }
  
  .jsoneditor-outer {
    border: 1px solid #444;
  }
  
  .jsoneditor-field {
    color: #d8d8d8;
  }
  
  .jsoneditor-value {
    color: #d8d8d8;
  }
  
  .jsoneditor-value.jsoneditor-string {
    color: #8dc891;
  }
  
  .jsoneditor-value.jsoneditor-number {
    color: #f0c674;
  }
  
  .jsoneditor-value.jsoneditor-boolean {
    color: #f2777a;
  }
  
  .jsoneditor-value.jsoneditor-null {
    color: #b294bb;
  }
  
  .jsoneditor-value.jsoneditor-object {
    color: #cc99cc;
  }
  
  .jsoneditor-value.jsoneditor-array {
    color: #99cc99;
  }
  
  .jsoneditor-schema,
  .jsoneditor-modes,
  .jsoneditor-text {
    color: #ccc;
  }
  
.catalys-utm-analytics {
    padding: 20px;
    background-color: #f7f9fc; /* Light background for contrast */
  }
  
  .error-message {
    color: red;
    margin: 10px 0;
    font-weight: bold;
  }
  
  .catalys-table .ant-table-header {
    background-color: #4a90e2; /* Change header color */
    color: white; /* Change header text color */
    margin-top: 10px;
  }
  
  .table-row:hover {
    background-color: #e6f7ff; /* Change row color on hover */
  }
  
  .ant-table-cell {
    padding: 16px; /* Add padding to table cells */
    border: 1px solid #e6e6e6; /* Add border for cells */
  }
  
  .ant-table {
    border-radius: 8px; /* Rounded corners for the table */
    overflow: hidden; /* Hide overflow for rounded corners */
  }
  
body,
html {
  font-family: Open Sans, sans-serif;

  /* 👇️ or use Operating system default fonts 👇️ */
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-xl {
  font-size: 1.2rem;
  line-height: 2rem;
}
.text-lg-light {
  font-size: 1.125rem;
  line-height: 1.75rem;
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

@media (max-width: 950px) {
  .text-lg-light {
    font-size: 1rem;
  }
  .ant-form-item .ant-btn-block {
    width: 70%;
  }
}
.ant-table-wrapper .ant-table-title .css-dev-only-do-not-override-1e3x2xa {
  /* display: flex; */
  flex-wrap: wrap;
}
.live {
  width: 7.8px;
  height: 7.8px;
  color: red;
  margin-left: 10px;
  position: relative;
  background: radial-gradient(11.2px, currentColor 94%, #0000);
}

.live:before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 50%;
  background: radial-gradient(5.08px at bottom right, #0000 94%, currentColor)
      top left,
    radial-gradient(5.08px at bottom left, #0000 94%, currentColor) top right,
    radial-gradient(5.08px at top right, #0000 94%, currentColor) bottom left,
    radial-gradient(5.08px at top left, #0000 94%, currentColor) bottom right;
  background-size: 11.4px 11.4px;
  background-repeat: no-repeat;
  animation: loader 1.5s infinite cubic-bezier(0.3, 1, 0, 1);
}

@keyframes loader {
  33% {
    inset: -5.2px;
    transform: rotate(0deg);
  }

  66% {
    inset: -5.2px;
    transform: rotate(90deg);
  }

  100% {
    inset: 0;
    transform: rotate(90deg);
  }
}
.loader {
  width: 100px;
  height: 100px;
}
.loader img {
  margin-top: 27px;
  margin-left: -6px;
}

.loader::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  width: 100px;
  height: 100px;
  margin-left: -30px;
  border-radius: 50%;
  border-top: 4px solid #8900ff;
  border-right: 4px solid transparent;
  animation: spinner8217 0.8s linear infinite;
}

@keyframes spinner8217 {
  to {
    transform: rotate(360deg);
  }
}
.container-btn-file {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  background-color: #307750;
  color: #fff;
  border-style: none;
  padding: 1em 2em;
  border-radius: 0.5em;
  overflow: hidden;
  z-index: 1;
  box-shadow: 4px 8px 10px -3px rgba(0, 0, 0, 0.356);
  transition: all 250ms;
}
.container-btn-file input[type="file"] {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
.container-btn-file > svg {
  margin-right: 1em;
}
.container-btn-file::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 0;
  border-radius: 0.5em;
  background-color: #469b61;
  z-index: -1;
  transition: all 350ms;
}
.container-btn-file:hover::before {
  width: 100%;
}
